<section class="forgot-password-block w-full relative after:absolute bg-no-repeat bg-cover h-screen items-center bg-bottom flex justify-center after:content-[''] after:bg-[url('/assets/images/cloude.png')] after:bg-contain after:bg-no-repeat after:inline-block after:left-0 after:top-0 after:h-full after:w-full">
  <div *ngIf="showModal3"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative z-50 bg-white rounded-2xl shadow-2xl p-5 min-w-96">
      <div>
        <form [formGroup]="ForgotForm">
          <div class="w-full flex items-center justify-center py-4"><img
              src="../../../../assets/images/Email_Service-img.svg" alt="" /></div>
          <h4 class="xl:text-3xl text-2xl text-dark text-center font700 pt-4">Forgot password?</h4>
          <p class="text-center text-base text-gray px-10 py-4">Don't worry! Please enter the email address linked with<br>
            your account.</p>
          <div class="mb-5 relative">
            <label for="email" class="block mb-2 text-sm font500 text-dark">Email address</label>
            <input type="email" formControlName="email" (keydown)="checkEvent($event)"
              class="bg-slate-50 border border-slate-300 pl-12 h-14 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              placeholder="john@example.com" />
            <div *ngIf="!ForgotForm.controls?.['email']?.valid && ForgotForm.controls?.['email']?.dirty">
              <div
                *ngIf="ForgotForm.controls?.['email']?.hasError('invalidEmail') && ForgotForm.controls?.['email']?.value"
                class="text-red-500">Invalid email !</div>
              <div class="text-red-500" *ngIf="ForgotForm.controls?.['email']?.hasError('required')">
                This field is Mandatory !</div>

            </div>
            <span class="absolute top-11 left-4">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 12.98V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5"
                  stroke="#8F95B2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#8F95B2" stroke-width="1.5"
                  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>

          </div>
          <button type="submit" [disabled]="!ForgotForm.valid"
            class="text-white h-14 font500 text-base shadow-lg bg-blue hover:bg-hover_blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg w-full px-5 py-2.5 text-center"
            (click)="submitEmail('email')">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>


<!---------------modal popup OTP Verification---------------->
<div *ngIf="showModal"  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none
 justify-center items-center flex">
  <form>
    <div class="relative my-7 z-50 lg:text-2xl bg-white rounded-2xl shadow-2xl p-5  min-w-96">
      <div class="w-full flex items-center justify-center py-4"><img
          src="../../../../assets/images/otp-verification.svg" alt="" /></div>
      <h4 class="xl:text-3xl text-2xl text-dark text-center font700 pt-4">OTP Verification</h4>
      <p class="text-center text-base text-gray px-10 py-4">Enter the code sent to your registered email to reset your<br/>
        password.</p>
      <div class="mb-5 relative flex justify-center">
        <!-- <label for="text" class="block mb-2 text-sm font700 text-dark">Password</label>
        <input type="text" formControlName="otp" (keydown)="checkEvent($event)" (keypress)="numberOnly($event)"
          class="bg-slate-50 border border-slate-300 pl-12 h-14 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          placeholder="Enter password" />
        <div *ngIf="!otpForm.controls?.['otp']?.valid && otpForm.controls?.['otp']?.dirty">

          <div class="text-red-500" *ngIf="otpForm.controls?.['otp']?.hasError('required')">
            This field is Mandatory !</div>

        </div> -->
        <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)"></ngx-otp-input>
        <!-- <span class="absolute top-11 left-4">
          <img src="../../../../assets/images/lock.svg">
        </span> -->
        <!-- <span class="absolute top-11 right-3">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.5299 9.46998L9.46992 14.53C8.81992 13.88 8.41992 12.99 8.41992 12C8.41992 10.02 10.0199 8.41998 11.9999 8.41998C12.9899 8.41998 13.8799 8.81998 14.5299 9.46998Z"
            stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M5.59984 17.76C4.59984 16.9 3.68984 15.84 2.88984 14.59C1.98984 13.18 1.98984 10.81 2.88984 9.4C4.06984 7.55 5.50984 6.1 7.11984 5.13"
            stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998" stroke="#8F95B2" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M8.41992 19.53C9.55992 20.01 10.7699 20.27 11.9999 20.27C15.5299 20.27 18.8199 18.19 21.1099 14.59C22.0099 13.18 22.0099 10.81 21.1099 9.39999C20.7799 8.87999 20.4199 8.38999 20.0499 7.92999"
            stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52" stroke="#8F95B2" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9.47 14.53L2 22" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M22.0003 2L14.5303 9.47" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </span> -->
      </div>
      <p class="py-3 text-center pb-7 text-gray text-sm">Didn't receive OTP? 
        <a class="text-blue font700 cursor-pointer" (click)="submitEmail('resend')">Resend OTP</a></p>
      <button type="submit" class="text-white h-14 font500 text-base shadow-lg  bg-blue
       hover:bg-hover_blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium 
       rounded-lg w-full px-5 py-2.5 text-center"
        (click)="submitPassword('otp-screen')" > Verify </button>
    </div>
  </form>
</div>


<!---------------modal Create new password---------------->
<div *ngIf="showModal2==true"
  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative my-6 xl:w-2/6 lg:w-3/6 md:w-4/6 z-50 bg-white rounded-2xl shadow-2xl p-5 mx-4">
    <!--content-->
    <form [formGroup]="newPasswordForm">
      <div class="border-0 relative flex flex-col w-fulloutline-none focus:outline-none">
        <!--header-->
        <div class="pb-3">
          <div>
            <div class="w-full flex items-center justify-center py-4"><img src="../../../../assets/images/password.svg"
                alt="" /></div>
            <h4 class="xl:text-3xl text-2xl text-dark text-center font700 pt-4">Create new password</h4>
            <p class="text-center text-base text-gray px-10 py-4">Your new password must be unique from<br> those previously
              used</p>
            <div class="mb-5 relative">
              <label for="password" class="block mb-2 text-sm font500  text-dark">Password</label>
              <input [type]="passwordType" id="password" formControlName="password" (keydown)="checkEvent($event)"
                class="bg-slate-50 border border-slate-300	h-14 pl-12 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter password" />
              <div
                *ngIf="!newPasswordForm.controls?.['password']?.valid && newPasswordForm.controls?.['password']?.dirty"
                class="error_main_div">
                <div *ngIf="newPasswordForm.controls?.['password']?.hasError('required')" class="text-red-500">This
                  field is
                  Mandatory ! </div>
                <div *ngIf="newPasswordForm.controls?.['password']?.hasError('pattern')" class="text-red-500">Password
                  must
                  contain uppercase, lowercase, number and special characters and min length should be 8.
                </div>
                <div *ngIf="newPasswordForm.controls?.['password']?.hasError('leadingTrailingSpaces')"
                  class="text-red-500">
                  Space are not allowed.
                </div>
              </div>
              <span class="absolute top-11 left-4">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#8F95B2" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M9.5 16C9.5 17.38 10.62 18.5 12 18.5C13.38 18.5 14.5 17.38 14.5 16C14.5 14.62 13.38 13.5 12 13.5"
                    stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M22 17V15C22 11 21 10 17 10H7C3 10 2 11 2 15V17C2 21 3 22 7 22H17C18.76 22 19.94 21.81 20.71 21.25"
                    stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span class="absolute top-11 right-3" (click)="hidesShowpassword()"><img src="{{passwordIcon}}"
                  alt="" /></span>
              <!-- <span class="absolute top-11 right-3">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.5299 9.46998L9.46992 14.53C8.81992 13.88 8.41992 12.99 8.41992 12C8.41992 10.02 10.0199 8.41998 11.9999 8.41998C12.9899 8.41998 13.8799 8.81998 14.5299 9.46998Z"
                  stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M5.59984 17.76C4.59984 16.9 3.68984 15.84 2.88984 14.59C1.98984 13.18 1.98984 10.81 2.88984 9.4C4.06984 7.55 5.50984 6.1 7.11984 5.13"
                  stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998" stroke="#8F95B2" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M8.41992 19.53C9.55992 20.01 10.7699 20.27 11.9999 20.27C15.5299 20.27 18.8199 18.19 21.1099 14.59C22.0099 13.18 22.0099 10.81 21.1099 9.39999C20.7799 8.87999 20.4199 8.38999 20.0499 7.92999"
                  stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52" stroke="#8F95B2" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.47 14.53L2 22" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M22.0003 2L14.5303 9.47" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span> -->
            </div>
            <div class="mb-5 relative">
              <label for="password" class="block mb-2 text-sm font500  text-dark">Confirm password</label>
              <input [type]="cpasswordType" id="password" formControlName="cPassword" (keydown)="checkEvent($event)"
                class="bg-slate-50 border border-slate-300	h-14 pl-12 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Confirm password" />
              <div
                *ngIf="!newPasswordForm.controls?.['cPassword']?.valid && newPasswordForm.controls?.['cPassword']?.dirty"
                class="error_main_div">
                <div
                  *ngIf="newPasswordForm.controls?.['cPassword']?.invalid && newPasswordForm.controls?.['cPassword']?.errors?.['ConfirmPassword']"
                  class="text-red-500">Password and confirm password is not match !
                </div>
                <div *ngIf="newPasswordForm.controls?.['cPassword']?.hasError('required')" class="text-red-500">This
                  field is
                  Mandatory !
                </div>
                <div *ngIf="newPasswordForm.controls?.['cPassword']?.hasError('leadingTrailingSpaces')"
                  class="text-red-500">
                  Space are not allowed.
                </div>
              </div>
              <span class="absolute top-11 left-4">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#8F95B2" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M9.5 16C9.5 17.38 10.62 18.5 12 18.5C13.38 18.5 14.5 17.38 14.5 16C14.5 14.62 13.38 13.5 12 13.5"
                    stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M22 17V15C22 11 21 10 17 10H7C3 10 2 11 2 15V17C2 21 3 22 7 22H17C18.76 22 19.94 21.81 20.71 21.25"
                    stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <!-- <span class="absolute top-11 right-3">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.5299 9.46998L9.46992 14.53C8.81992 13.88 8.41992 12.99 8.41992 12C8.41992 10.02 10.0199 8.41998 11.9999 8.41998C12.9899 8.41998 13.8799 8.81998 14.5299 9.46998Z"
                  stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M5.59984 17.76C4.59984 16.9 3.68984 15.84 2.88984 14.59C1.98984 13.18 1.98984 10.81 2.88984 9.4C4.06984 7.55 5.50984 6.1 7.11984 5.13"
                  stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998" stroke="#8F95B2" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M8.41992 19.53C9.55992 20.01 10.7699 20.27 11.9999 20.27C15.5299 20.27 18.8199 18.19 21.1099 14.59C22.0099 13.18 22.0099 10.81 21.1099 9.39999C20.7799 8.87999 20.4199 8.38999 20.0499 7.92999"
                  stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52" stroke="#8F95B2" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.47 14.53L2 22" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M22.0003 2L14.5303 9.47" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span> -->
              <span class="absolute top-11 right-3" (click)="hidesShowConfirmpassword()"><img src="{{cpasswordIcon}}"
                  alt="" /></span>
            </div>
            <button type="submit" [disabled]="newPasswordForm.invalid"
              class="text-white h-14 font500 text-base shadow-lg
             bg-blue hover:bg-hover_blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg w-full px-5 py-2.5 text-center mb-4"
              (click)="submitPassword('changepassword')">
              Continue
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>


<!---------------modal popup password changed---------------->
<div *ngIf="showModal4"
  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative my-6 xl:w-2/6 lg:w-3/6 md:w-4/6 z-50 bg-white rounded-2xl shadow-2xl p-5 mx-4">
    <div class="w-full flex items-center justify-center py-4"><img src="../../../../assets/images/thik-arrow.svg"
        alt="" /></div>
    <h4 class="xl:text-3xl text-2xl text-dark text-center font700 pt-4">Password Changed!</h4>
    <p class="text-center text-base text-gray px-10 py-4">Your password has been successfully changed</p>
    <button type="button"
      class="text-white h-14 font500 text-base shadow-lg bg-blue hover:bg-hover_blue focus:ring-4 focus:outline-none 
      focus:ring-blue-300 font-medium rounded-lg w-full px-5 py-2.5 text-center" [routerLink]="['/login']">
      Back to login
    </button>
  </div>
</div>
</section>