import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/common/api/api.service';
import { MessagehandlingService } from 'src/app/common/message/message-service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  signupForm: FormGroup
  passwordType = "password"
  cpasswordType = "password"
  passwordIcon: any = "../../../../assets/images/slash-aye-light-gray.svg";
  cpasswordIcon: any = "../../../../assets/images/slash-aye-light-gray.svg";
  type: any
  currentRoute: any;
  constructor(private route: Router, private formBuilder: FormBuilder, private api: ApiService, private message: MessagehandlingService) {
    this.signupForm = formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this), this.noLeadingTrailingSpaces()]],
      password: ['', [Validators.required, Validators.minLength(7), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$"), this.noLeadingTrailingSpaces()]],
      cPassword: ['', [Validators.required, this.noLeadingTrailingSpaces()]],
      terms: ['', [Validators.required]],
    }, { validator: this.checkPasswords })

  }

  terms() {
    window.open('/landing/terms-condition', '_blank')
  }

  privacy() {
    window.open('/landing/privacy-policy', '_blank')
  }

  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('cPassword')?.value
    if (pass != confirmPass) {
      control.get('cPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }

  noLeadingTrailingSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string' && this.hasLeadingTrailingOrConsecutiveSpaces(control.value)) {
        return { leadingTrailingSpaces: true };
      }
      return null;
    };
  }

  hasLeadingTrailingOrConsecutiveSpaces(value: string): boolean {
    if (value.trim() !== value) {
      return true;
    }
    if (value.includes(' ')) {
      return true;
    }

    return false;
  }

  // emailValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const value = control.value;
  //     if (value == null || typeof value !== 'string') {
  //       return { invalidEmail: true };
  //     }

  //     const emailRegex = /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z]{2,15})+$/i;
  //     if (!emailRegex.test(value.toLowerCase())) {
  //       return { invalidEmail: true };
  //     }

  //     return null;
  //   };
  // }
  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }

  hidesShowpassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === '../../../../assets/images/slash-aye-light-gray.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/slash-aye-light-gray.svg';
  }

  hidesShowConfirmpassword() {
    this.cpasswordType = this.cpasswordType === 'text' ? 'password' : 'text';
    this.cpasswordIcon = this.cpasswordIcon === '../../../../assets/images/slash-aye-light-gray.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/slash-aye-light-gray.svg';
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  signUp() {
    this.validateEmail()
  }

  validateEmail() {
    let email = this.signupForm.value.email;
    let password = this.signupForm.value.password;
    let fd = new FormData();
    fd.append('email', email);
     localStorage.setItem('email', email);
     localStorage.setItem('password', password);
    this.api.validateEmail(fd).subscribe((res: any) => {
      console.log(res);
      // this.signupForm.reset()
      if (res.msg === 'Success') {
        this.type = localStorage.getItem('interface')
        this.signup()
        if (this.type == 'recipient') {
          this.route.navigate(['recipient/otp-verification']);
        } else {
          this.route.navigate(['provider/otp']);
        }
      } else {
        this.message.successMessage(res.msg, 1000);
      }
    });
  }

  signup() {
    if (this.signupForm.value.terms == false) {
      this.message.errorMessage('Please select the terms and conditions', 2000)
    } else {
      let fd = new FormData()
      fd.append('email', this.signupForm.value.email)
      this.api.sendOtpRegistration(fd).subscribe((res: any) => {
        this.message.successMessage(res.msg, 1000)

      })
    }
  }
}
