import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'years'
})
export class YearsPipe implements PipeTransform {
  transform(value: number | string | null | undefined): string {
    if (value == null) {
      return ''; // or return a default message if needed
    }
    
    if (typeof value === 'number') {
      return value === 1 ? `${value} year` : `${value} years`;
    }
    
    if (typeof value === 'string') {
      const parts = value.split('-').map(part => parseInt(part, 10));
      if (parts.length === 2 && parts[1] === 1) {
        return `${value} year`;
      } else {
        return `${value} years`;
      }
    }

    return `${value}`;
  }
}
