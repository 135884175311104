import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userData$: Observable<any> = this.userDataSubject.asObservable();
  private unreadCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public unreadCount$: Observable<number> = this.unreadCountSubject.asObservable();

  constructor(private api: ApiService) {
     this.fetchUser()
  }

  fetchUser(): void {
    let fd = new FormData();
    this.api.getUser(fd).subscribe(
      (res: any) => {
        if (res && res.data) {
          this.userDataSubject.next(res);
          this.unreadCountSubject.next(res.unread_count || 0); 
          localStorage.setItem('userRole', res.data.type);
        }
      },
      (error) => {
        console.error('Error fetching user data:', error);
      }
    );
  }

  updateUnreadCount(count: number): void {
    this.unreadCountSubject.next(count);
  }
  updateUser(updatedUserData: any): void {
    this.userDataSubject.next(updatedUserData);
  }

  clearUserData(): void {
    this.userDataSubject.next(null);
  }
}
