import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userData$: Observable<any> = this.userDataSubject.asObservable();

  constructor(private api: ApiService) {
    // this.fetchUser()
  }

  fetchUser(): void {
    let fd = new FormData();
    this.api.getUser(fd).subscribe(
      (res: any) => {
        if (res && res.data) {
          console.log('User data fetched:', res.data); // Debug statement
          this.userDataSubject.next(res.data);
        } else {
          console.error('Invalid response format:', res);
        }
      },
    );
  }

  updateUser(updatedUserData: any): void {
    this.userDataSubject.next(updatedUserData);
  }

  clearUserData(): void {
    this.userDataSubject.next(null);
  }
}
