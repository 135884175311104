import { Component } from '@angular/core';
import { NotificationService } from './common/notification/notification.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'crew-hub';
  constructor(
    private notificationService: NotificationService

  ) {
     this.notificationService.requestPermission();
     this.notificationService.listen()
  }
}
