// import { Injectable } from '@angular/core';
// import { HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable({
//     providedIn: 'root'
// })
// export class HttpInterceptorService {

//     constructor() { }
//       intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//         var token:any = sessionStorage.getItem('crewHub')
//         if (token) {
//           const authReq = request.clone({

//             headers: new HttpHeaders({
//               'Authorization': 'Bearer ' + token
//             })
//           });
//           return next.handle(authReq);
//         } 
//         else {
//           const authReq = request.clone({
//           });
//           return next.handle(authReq);
//         }
//       }


      import { Injectable } from '@angular/core';
      import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
      import { Observable } from 'rxjs';
      
      @Injectable({
        providedIn: 'root'
      })
      export class HttpInterceptorService implements HttpInterceptor {
      
        private excludedUrls: string[] = ['https://api.openai.com/v1/chat/completions'];
        private apiKey = 'sk-proj-PgSQWrgIki7qMvp3Pj2PT3BlbkFJZQMEvZEyChnhVLcu0FDy';
      
        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
          const isExcludedUrl = this.excludedUrls.some(url => request.url.includes(url));
          let token = sessionStorage.getItem('crewHub');
      
          if (isExcludedUrl) {
            // If the request is to the OpenAI API, use the API key
            const clonedRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.apiKey}`
              }
            });
            return next.handle(clonedRequest);
          } else if (token) {
            const clonedRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
            return next.handle(clonedRequest);
          } else {
            return next.handle(request);
          }
        }
      }
      
