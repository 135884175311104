import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { ApiService } from 'src/app/common/api/api.service';
import { MessagehandlingService } from 'src/app/common/message/message-service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  otp: any;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  }
  ForgotForm: FormGroup<any>
  newPasswordForm: FormGroup<any>
  showModal = false;
  showModal2 = false;
  showModal3 = true;
  showModal4 = false;
  otpvalue: any = ''
  passwordType = "password"
  cpasswordType = "password"
  passwordIcon: any = "../../../../assets/images/slash-aye-light-gray.svg";
  cpasswordIcon: any = "../../../../assets/images/slash-aye-light-gray.svg";
 
  constructor(private formBuilder: FormBuilder, public api: ApiService, public router: Router, public message: MessagehandlingService) {
    this.ForgotForm = this.formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
    })
    this.newPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(7), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$"), this.noLeadingTrailingSpaces()]],
      cPassword: ['', [Validators.required, this.noLeadingTrailingSpaces()]],
    },{ validator: this.checkPasswords })
  }

  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }

  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('cPassword')?.value
    if (pass != confirmPass) {
      control.get('cPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }

  noLeadingTrailingSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string' && this.hasLeadingTrailingOrConsecutiveSpaces(control.value)) {
        return { leadingTrailingSpaces: true };
      }
      return null;
    };
  }

  hasLeadingTrailingOrConsecutiveSpaces(value: string): boolean {
    if (value.trim() !== value) {
      return true;
    }
    if (value.includes(' ')) {
      return true;
    }

    return false;
  }

  hidesShowpassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === '../../../../assets/images/slash-aye-light-gray.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/slash-aye-light-gray.svg';
  }

  hidesShowConfirmpassword() {
    this.cpasswordType = this.cpasswordType === 'text' ? 'password' : 'text';
    this.cpasswordIcon = this.cpasswordIcon === '../../../../assets/images/slash-aye-light-gray.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/slash-aye-light-gray.svg';
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  toggleModal() {
    this.showModal = !this.showModal;
    this.showModal2 = false;
    this.showModal3 = false;
    this.showModal4 = false
  }
  toggleModal2() {
    this.showModal2 = !this.showModal2;
    this.showModal = false;
    this.showModal3 = false;
    this.showModal4 = false
  }
  toggleModal4() {
    this.showModal4 = !this.showModal4;
    this.showModal2 = false;
    this.showModal = false;
    this.showModal3 = false
  }

  submitEmail(type:any) {
    console.log( this.showModal );
    
    debugger
   switch (type) {
    case 'email':
      let fd = new FormData
      fd.append('email', this.ForgotForm.value.email)
      this.api.forgotPassword(fd).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.message.successMessage(res.msg, 1000)
          this.toggleModal()
        }
      })
      break;
      case 'resend':
        let fd1 = new FormData
        fd1.append('email', this.ForgotForm.value.email)
        this.api.forgotPassword(fd1).subscribe((res: any) => {
            this.message.successMessage(res.msg, 1000)
            this.showModal=true
          
        })
        break;
    default:
      break;
   }
   
  }

  handeOtpChange(event: any): void {
    const otpValue = event.target ? event.target.value : event;
    this.otp = otpValue;
  }

  handleFillEvent(event: any): void {
    const otpValue = event.target ? event.target.value : event;
    this.otp = otpValue;
  }



  submitPassword(type:any) {
    switch (type) {
      case 'otp-screen':
        let fd1 = new FormData
        fd1.append('email', this.ForgotForm.value.email)
        fd1.append('otp', this.otp)
       this.api.resetPasswordAuth(fd1).subscribe((res: any) => {
          console.log(res);
          if (res) {
            this.message.successMessage(res.msg,1000)
            this.toggleModal2()
          }
        })
        break;
        case 'changepassword':
          let fd = new FormData
          fd.append('email', this.ForgotForm.value.email)
          fd.append('otp', this.otp)
          fd.append('password', this.newPasswordForm.value.password)
          this.api.resetPasswordAuth(fd).subscribe((res: any) => {
            console.log(res);
            if (res) {
              this.message.successMessage(res.msg,1000)
              this.toggleModal4()
            }
      
          })
        break; 
      default:
        break;
    }
   
  }


}
