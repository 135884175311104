// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl :'https://yogesh.parastechnologies.in/crewhub/',
baseImageURL:'https://yogesh.parastechnologies.in/crewhub/public/uploads/user_images/',
baseDocumentUrl:'https://yogesh.parastechnologies.in/crewhub/public/uploads/user_files/',
qrCodeUrl:'https://yogesh.parastechnologies.in/crewhub/api/profile/',
GOOGLE_CLIENT_ID:'138286387788-hp2d2uv3bqrkaitjhmmtenud9a6a0s45.apps.googleusercontent.com',
// 'https://yogesh.parastechnologies.in/crewhub/public/uploads/qrcodes'

firebaseConfig: {
  apiKey: "AIzaSyC9vBAt3fV-6n1fmGwGC86hxzoadwf-ETg",
  authDomain: "crew-hub-5a161.firebaseapp.com",
  projectId: "crew-hub-5a161",
  storageBucket: "crew-hub-5a161.firebasestorage.app",
  messagingSenderId: "138286387788",
  appId: "1:138286387788:web:f760ab74ac21d9df6f1ae9",
  measurementId: "G-G0D3F2YXSL"
}
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
