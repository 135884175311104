import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/care-shared auth/login/login.component';
import { SignUpComponent } from './components/care-shared auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/care-shared auth/forgot-password/forgot-password.component';
import { EmailVerificationComponent } from './components/care-shared auth/email-verification/email-verification.component';
const routes: Routes = [
  { path: 'landing', loadChildren: () => import('./components/care-landing/landing.module').then(m => m.LandingModule) },
  { path: 'recipient', loadChildren: () => import('./components/care-recipient/care-recipient/care-recipient.module').then(m => m.CareRecipientModule) },
  { path: 'provider', loadChildren: () => import('./components/care-provider/care-provider/care-provider.module').then(m => m.CareProviderModule) },
  {path:'login', component:LoginComponent},
  {path:'signup', component:SignUpComponent},
  {path:'forgot-password', component:ForgotPasswordComponent},
  {path:'**', redirectTo:'landing'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
