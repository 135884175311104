import { Injectable, NgZone } from '@angular/core';
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
@Injectable()
export class NotificationService {
    deviceToken: string | null = null;
    message: any;
    private eventSource = new BehaviorSubject<string>("");
    eventSubject = this.eventSource.asObservable();
    private vapidKey = "BLAMeBDX81n-cE037bR50zR7KfxyrsD3WaxaHJAitrMmBkUcB_EpmEtJeC2o3A492s6PpJbePlfoRoYAIW3M5HM";
    userRole: any
    constructor(private router: Router, private userService: UserService, private ngZone: NgZone) {
        this.userService.fetchUser();
        this.userService.userData$.subscribe((data: any) => {
            if (data) {
                this.userRole = data?.data?.type;
                console.log('User role:', this.userRole);
            }
        });

        this.listen();
    }



    // listen() {
    //     const messaging = getMessaging();
    //     onMessage(messaging, (payload: any) => {
    //         console.log('Message received:', payload);

    //         const title = payload.notification?.title || "New Notification";
    //         const body = payload.notification?.body || "You have a new message.";
    //         let parsedData: any;
    //         let notificationType: string | undefined = payload.data?.type;

    //         if (!notificationType && payload.data?.data) {
    //             try {
    //                 parsedData = JSON.parse(payload.data.data);
    //                 notificationType = parsedData.type;
    //             } catch (error) {
    //                 console.error('Error parsing notification data:', error);
    //             }
    //         }

    //         console.log('Extracted Notification Type:', notificationType);

    //         // Check if the user has granted permission for notifications
    //         if (Notification.permission === 'granted') {
    //             const notification = new Notification(title, { body: body });

    //             notification.onclick = () => {
    //                 this.ngZone.run(() => {
    //                     this.navigateBasedOnRole(notificationType, parsedData);
    //                 });
    //             };
    //         } else {
    //             console.warn('Notification permission not granted');
    //         }
    //     });
    // }

    private navigateBasedOnRole(notificationType: string | undefined, parsedData: any) {
        this.userRole = localStorage.getItem('userRole'); 
        console.log(this.userRole);
        if (!notificationType) return;

        if (this.userRole === 'recipient') {
            this.handleRecipientNotification(notificationType, parsedData);
        } else if (this.userRole === 'careprovider') {
            this.handleProviderNotification(notificationType, parsedData);
        }
    }

    private handleRecipientNotification(notificationType: string, parsedData: any) {
        switch (notificationType) {
            case 'accept_req_notification':
            case 'reject_req_notification':
            case 'accept_shift_notification':
            case 'reject_shift_notification':
            case 'request_notification':
            case 'shift_completed':
                this.router.navigate(['/recipient/notification']);
                break;
            case 'medication_notification':
                this.router.navigate(['/recipient/care-recipient/medication-info/', parsedData?.id]);
                break;
            case 'medication_notification_self':
                this.router.navigate(['/recipient/care-recipient/medication-info']);
                break;
            case 'upcoming_shift_notification':
                this.router.navigate(['/recipient/home/home-detail']);
                break;
            case 'appointment_notification':
                this.router.navigate(['/recipient/calendar-roster']);
                break;
            case 'task_notification':
                this.router.navigate(['/recipient/task']);
                break;
            case 'share_note_notification':
                this.router.navigate(['/recipient/notes-and-report-sharing']);
                break;
            case 'chat_notification':
                this.router.navigate(['/recipient/message']);
                break;
            default:
                console.warn('Unhandled recipient notification type:', notificationType);
                break;
        }
    }


    private handleProviderNotification(notificationType: string, parsedData: any) {
        switch (notificationType) {
            case 'shift_notification':
                this.router.navigate(['/provider/home/detail-shift/', parsedData?.id])
                break;
            case 'request_notification':
                this.router.navigate(['/provider/care-list/recipient-profile-details', parsedData?.id])
                break;
            case 'share_note_notification':
                this.router.navigate(['/provider/notes-and-report-sharing']);
                break;
            case 'chat_notification':
                this.router.navigate(['/provider/message']);
                break;
            case 'accept_req_notification':
            case 'reject_req_notification':
            case 'accept_shift_notification':
            case 'reject_shift_notification':
            case 'shift_completed':
                this.router.navigate(['/provider/notification']);
                break;
            default:
                console.warn('Unhandled provider notification type:', notificationType);
                break;
        }
    }


    async requestPermission(): Promise<string | null> {
        try {
            const messaging = getMessaging();
            const token = await getToken(messaging, { vapidKey: this.vapidKey });

            if (token) {
                console.log('FCM Token:', token);
                return token;
            } else {
                console.warn('No registration token available. Request permission again.');
                return null;
            }
        } catch (error) {
            console.error('Permission denied for notifications:', error);
            return null;
        }
    }

    listen() {
        const messaging = getMessaging();
        onMessage(messaging, (payload: any) => {
            console.log('Message received:', payload);

            const title = payload.notification?.title || "New Notification";
            const body = payload.notification?.body || "You have a new message.";
            let parsedData: any;
            let notificationType: string | undefined = payload.data?.type;

            if (!notificationType && payload.data?.data) {
                try {
                    parsedData = JSON.parse(payload.data.data);
                    notificationType = parsedData.type;
                    console.log(parsedData);
                } catch (error) {
                    console.error('Error parsing notification data:', error);
                }
            }

            console.log('Extracted Notification Type:', notificationType);

            // const notificationTypes = [
            //     'accept_req_notification',
            //     'reject_req_notification',
            //     'accept_shift_notification',
            //     'reject_shift_notification',
            //     'shift_completed'
            // ];

            if (Notification.permission === 'granted') {
                const notification = new Notification(title, {
                    body: body,
                });

                notification.onclick = () => {
                    this.ngZone.run(() => {
                        this.navigateBasedOnRole(notificationType, parsedData);
                    });
                }
            } else {
                console.warn('Notification permission not granted');
            }
        });
    }

    // ✅ Delete Firebase Token
    async deleteFirebaseToken() {
        try {
            const messaging = getMessaging();
            const token = await getToken(messaging, { vapidKey: this.vapidKey });

            if (token) {
                const deleted = await deleteToken(messaging);
                if (deleted) {
                    console.log('Firebase Token deleted successfully.');
                }
            }
        } catch (error) {
            console.error('Error deleting Firebase token:', error);
        }
    }

    // ✅ Show Notification
    showNotification(value: any) {
        if (Notification.permission !== 'granted') {
            console.warn('Notifications are not allowed.');
            return;
        }

        const title = value.title || "New Notification";
        const body = value.body || "You have a new message.";

        const notification = new Notification(title, {
            body: body,
            icon: '/assets/images/main-logo.png',
        });

        setTimeout(() => {
            notification.close();
        }, 10000);

        notification.addEventListener('click', () => {
            window.open('https://crew-hub-angular.harishparas.com/', '_blank');
        });
    }


    // ✅ Emit Event for UI updates
    emitEvent(value: string) {
        this.eventSource.next(value);
    }
}
