import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api/api.service';
import { MessagehandlingService } from 'src/app/common/message/message-service';
import { UserService } from 'src/app/common/service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm!: FormGroup
  passwordType = "password"
  passwordIcon: any = "../../../../assets/images/slash-aye-light-gray.svg";
  constructor(private route: Router, private formBuilder: FormBuilder, private api: ApiService, private userService: UserService, private message: MessagehandlingService) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
      password: new FormControl('', Validators.required),
      rememberme: [false],
    })

  }

  ngOnInit() {
    this.checkRememberedLogin()
  }

  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }


  hidesShowpassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === '../../../../assets/images/slash-aye-light-gray.svg' ? '../../../../assets/images/eye-light-gray.svg' : '../../../../assets/images/slash-aye-light-gray.svg';
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  checkRememberedLogin(): void {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    const rememberedPassword = localStorage.getItem('rememberedPassword');
    if (rememberedEmail && rememberedPassword) {
      this.loginForm.patchValue({
        email: rememberedEmail,
        password: rememberedPassword,
        rememberme: true
      });
    }
  }

  // submit() {
  //   console.log(this.loginForm.value);
  //   let fd = new FormData
  //   fd.append('email', this.loginForm.value.email)
  //   fd.append('password', this.loginForm.value.password)
  //   this.api.login(fd).subscribe((res: any) => {
  //     console.log(res);
  //     sessionStorage.setItem('crewHub', res.data.token)
  //     sessionStorage.setItem('profileData',JSON.stringify(res.data))
  //     if (res.data.type === 'careprovider') {
  //       if (res.data.is_email_verify === 0 && res.data.is_phone_verify === 0) {
  //         this.route.navigate(['provider/otp']);
  //       } else if (res.data.is_email_verify === 0) {
  //         this.route.navigate(['provider/otp']);
  //       } else if (res.data.is_phone_verify === 0) {
  //         this.route.navigate(['provider/profile']);
  //       } else {
  //         this.route.navigate(['provider/home']);
  //       }
  //     }
  //     if (res.data.type === 'recipient') {
  //       if (res.data.is_email_verify === 0 && res.data.is_phone_verify === 0) {
  //         this.route.navigate(['recipient/otp-verification']);
  //       } else if (res.data.is_email_verify === 0) {
  //         this.route.navigate(['recipient/otp-verification']);
  //       } else if (res.data.is_phone_verify === 0) {
  //         this.route.navigate(['recipient/profile']);
  //       } else {
  //         this.route.navigate(['recipient/home']);
  //       }
  //     }


  //   })
  //   // this.route.navigate(['recipient/home'])
  // }


  submit() {
    if (this.loginForm.valid) {
      const { email, password, rememberme } = this.loginForm.value;
      const fd = new FormData();
      fd.append('email', email);
      fd.append('password', password);
      fd.append('device_type','web')
      this.api.login(fd).subscribe(
        (res: any) => {
          console.log(res);
          sessionStorage.setItem('crewHub', res.data.token);
          sessionStorage.setItem('profileData', JSON.stringify(res.data));
          this.userService.fetchUser()
          if (res.data.type === 'careprovider') {
            if (res.data.is_email_verify === 0 && res.data.is_phone_verify === 0) {
              this.route.navigate(['provider/otp']);
            } else if (res.data.is_email_verify === 0) {
              this.route.navigate(['provider/otp']);
            } else if (res.data.is_phone_verify === 0) {
              this.route.navigate(['provider/profile']);
            } else if (res.data.ndis_back == null || res.data.ndis_front == null) {
              this.route.navigate(['provider/worker']);
            } else if (res.data.photo == null) {
              this.route.navigate(['provider/upload-profile']);
            }
            else {
              this.route.navigate(['provider/home']);
            }
          }
          else if (res.data.type === 'recipient') {
            if (res.data.is_email_verify === 0 && res.data.is_phone_verify === 0) {
              this.route.navigate(['recipient/otp-verification']);
            } else if (res.data.is_email_verify === 0) {
              this.route.navigate(['recipient/otp-verification']);
            } else if (res.data.is_phone_verify === 0) {
              this.route.navigate(['recipient/profile']);
            } 
            else if (res.data.subscription == null) { 
              this.route.navigate(['recipient/payment']);
            }
            else if (res.data.subscription == null&& res.data.status==0) { 
              this.route.navigate(['recipient/home']);
            }
            else {
              this.route.navigate(['recipient/home']);
            }
          }
          if (rememberme) {
            localStorage.setItem('rememberedEmail', email);
            localStorage.setItem('rememberedPassword', password);
          } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
          }
        },
        (error) => {
          console.error('Login failed', error);
        }
      );
    }
  }

}
