<section class="body-font h-screen grid content-center">
  <div class="flex flex-wrap ">
    <div class="2xl:w-1/2 xl:w-1/2 lg:h-full md:w-full sm:w-full grid items-center h-screen w-full md:py-0 py-10">
      <form class="mx-auto 2xl:w-6/12 xl:w-8/12 lg:w-12/12 md:w-8/12 sm:w-full md:px-0 sm:px-10 w-full px-6"
        [formGroup]="signupForm">
        <!-- <img src="../../../../assets/images/logo-icon.svg" alt="" class="lg:invisible visible mx-auto mb-5"> -->
        <h2 class="pb-2 lg:text-4xl sm:text-3xl text-3xl  font700 md:text-center lg:text-start text-center">
          Let's get started.</h2>
        <p class="text-base text-gray-500 pb-7 md:text-center lg:text-start text-center">Create an account by filling in
          the information below</p>
        <div class="mb-5 relative">
          <label for="email" class="block mb-2 text-sm font400 text-dark">Email address</label>
          <input type="email"
            class="bg-slate-50 border border-slate-300 pl-12 h-14 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="john@example.com" formControlName="email" (keydown)="checkEvent($event)" />
          <div *ngIf="!signupForm.controls?.['email']?.valid && signupForm.controls?.['email']?.dirty">
            <div
              *ngIf="signupForm.controls?.['email']?.hasError('invalidEmail') && signupForm.controls?.['email']?.value"
              class="text-red-500">Invalid email !</div>
            <div class="text-red-500" *ngIf="signupForm.controls?.['email']?.hasError('required')">
              This field is Mandatory !</div>
            <div *ngIf="signupForm.controls?.['email']?.hasError('leadingTrailingSpaces')" class="text-red-500">
              Space are not allowed.
            </div>
          </div>
          <span class="absolute top-11 left-4">
            <img src="../../../../assets/images/email-light-gray.svg" alt="">
          </span>
        </div>
        <div class="mb-5 relative">
          <label for="password" class="block mb-2 text-sm font400 text-dark"
            (keydown)="checkEvent($event)">Password</label>
          <input [type]="passwordType" id="password" formControlName="password"
            class="bg-slate-50 border border-slate-300 h-14 pl-12 pe-12 text-gray-900 text-sm rounded-lg
             focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Enter password" />
          <div *ngIf="!signupForm.controls?.['password']?.valid && signupForm.controls?.['password']?.dirty"
            class="error_main_div">
            <div *ngIf="signupForm.controls?.['password']?.hasError('required')" class="text-red-500">This field is
              Mandatory ! </div>
            <div *ngIf="signupForm.controls?.['password']?.hasError('pattern')" class="text-red-500">Password must
              contain uppercase, lowercase, number and special characters and min length should be 8.
            </div>
            <div *ngIf="signupForm.controls?.['password']?.hasError('leadingTrailingSpaces')" class="text-red-500">
              Space are not allowed.
            </div>

          </div>
          <span class="absolute top-11 left-4">
            <img src="../../../../assets/images/lock-light-gray.svg" alt="">
          </span>
          <!-- <span class="absolute top-11 right-3">
            <img src="../../../../assets/images/slash-aye-light-gray.svg" alt="">
          </span> -->
          <span class="absolute top-11 right-3" (click)="hidesShowpassword()"><img src="{{passwordIcon}}"
              alt="" /></span>
        </div>
        <div class="mb-5 relative">
          <label for="password" class="block mb-2 text-sm font400 text-dark">Confirm Password</label>
          <input [type]="cpasswordType" id="password" formControlName="cPassword" (keydown)="checkEvent($event)"
            class="bg-slate-50 border border-slate-300	h-14 pl-12 pe-12 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Confirm Password" />
          <div *ngIf="!signupForm.controls?.['cPassword']?.valid && signupForm.controls?.['cPassword']?.dirty"
            class="error_main_div">
            <div *ngIf="signupForm.controls?.['cPassword']?.invalid && signupForm.controls?.['cPassword']?.errors?.['ConfirmPassword']"
              class="text-red-500">Password and confirm password is not match ! 
            </div>
            <div *ngIf="signupForm.controls?.['cPassword']?.hasError('required')" class="text-red-500">This field is
              Mandatory !

            </div>
            <div *ngIf="signupForm.controls?.['cPassword']?.hasError('leadingTrailingSpaces')" class="text-red-500">
              Space are not allowed.
            </div>
          </div>
          <span class="absolute top-11 left-4">
            <img src="../../../../assets/images/lock-light-gray.svg" alt="">
          </span>
          <span class="absolute top-11 right-3" (click)="hidesShowConfirmpassword()"><img src="{{cpasswordIcon}}"
              alt="" /></span>
        </div>
        <div class="flex align-items-center justify-between">
          <div class="flex items-start mb-5">
            <div class="flex items-center h-5">
              <input id="remember" type="checkbox" value=""
                class="w-4 h-4 border border-slate-300 rounded checked:bg-blue focus:bg-blue"
                formControlName="terms" />
            </div>
            <label for="remember" class="ms-2 text-base text-gray-500 font400 dark:text-gray-300">
              By signing up, you are agreeing to our <span class="text-blue font700 hover:text-hover_blue cursor-pointer"> <a (click)="terms()">Terms & Conditions</a>
               </span> and <span class="text-blue hover:text-hover_blue cursor-pointer font700"><a (click)="privacy()">Privacy Policy.</a>
                </span></label>
          </div>
        </div>
        <button type="button" (click)="signUp()" [disabled]="signupForm.invalid|| this.signupForm.value.terms==false"
          class="text-white h-14 font500 text-base shadow-lg bg-blue hover:bg-hover_blue font-medium rounded-lg w-full px-5 py-2.5 text-center">
          Sign Up </button>
        <p class="pt-3 text-gray-500">Already have an account? <a class="text-blue font700 underline"
            [routerLink]="['/login']">Sign In</a></p>
      </form>
    </div>
    <div class="lg:w-1/2 md:w-1/2 w-full bg-gray-50 h-screen bg-no-repeat bg-cover bg-bottom fixed 
    top-0 bottom-0 right-0 invisible lg:visible" style="background-image: url('../../../assets/images/login-side-img.png')">
      <div class="d-flex align-items-center w-full h-full justify-items-center pt-20 logo-block-img">
        <img src="../../../assets/images/crew-hub-logo.png" alt="" class="mx-auto" />
      </div>
    </div>
  </div>
</section>